<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ title }}
    </v-card-title>

    <MasterDetail
      :actionBarButtons="actionBarButtons"
      asyncExportType="pareamento"
      class="master-detail"
      :cols="cols"
      :contextOptions="contextOptions"
      :customResource="customResource"
      :confirmDeleteMessage="confirmDeleteMessage"
      :hasExportCSV="false"
      hasYearFilter
      newButtonText="Adicionar Pareamento"
      :opts="opts"
      :selection.sync="rubricasSelecionadas"
      selectionCol
      @onOpenFormDialog="loadDescriptions"
    ></MasterDetail>

    <FormModal
      :cols="replicarFields"
      maxWidth="450px"
      :opened.sync="replicarModalOpen"
      :opts="opts"
      title="Replicar para outro ano base"
      :value.sync="replicarModalData"
      @save="replicarRubricas"
    ></FormModal>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BeneficiosEnum, BeneficiosLabels } from '@/core/enums/beneficios';
import yearsOptions from '@/helpers/yearsOptions';

export default {
  components: {
    "FormModal": () => import("@/components/form-modal.vue"),
    "MasterDetail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'selectedClient']),
    actionBarButtons: function () {
      return [
        {
          text: "Replicar",
          icon: "mdi-content-duplicate",
          hide: !this.rubricasSelecionadas?.length,
          action: () => {
            const templateIds = this.rubricasSelecionadas.map(({ id }) => id);
            this.replicarModalData = { templateIds };
            this.replicarModalOpen = true;
          },
        }
      ].filter(({ hide }) => !hide);
    },
    cols: function () {
      return [
        {
          key: 'codigo',
          name: 'Código Folha',
          hideInform: true,
        },
        {
          key: 'descricaoCliente',
          name: 'Descrição Folha',
          hideInform: true,
        },
        {
          key: 'rubId',
          name: 'Código Teros',
          hideInform: true,
        },
        {
          key: 'descricaoTeros',
          name: 'Descrição Teros',
          hideInform: true,
        },
        {
          key: 'itemId',
          name: 'Descrição Folha',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "descricoesCliente", key: "id", name: "nome" },
          rules: [{ rule: 'required' }],
          hideInTable: true,
        },
        {
          key: 'rubId',
          name: 'Descrição Teros',
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "descricoesTeros", key: "id", name: "nome" },
          rules: [{ rule: 'required' }],
          hideInTable: true,
        },
        {
          key: 'competenciaDe',
          name: 'Competência de',
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: 'required' }],
          colSize: 6,
        },
        {
          key: 'competenciaAte',
          name: 'Competência ate',
          type: this.$fieldTypes.MONTH,
          rules: [{ rule: 'required' }],
          colSize: 6,
        },
        {
          key: 'ano',
          name: 'Ano Base',
          hideInform: true,
        },
        {
          key: 'trabalho',
          name: 'Benefício',
          rel: { to: "trabalho", key: "id", name: "nome" },
          hideInform: true,
        },
        {
          key: 'modificado_em',
          name: 'Última atualização',
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];
    },
    contextOptions: function () {
      return [{
        name: "Replicar",
        limit: 1,
        icon: "mdi mdi-content-duplicate",
        cb: (row) => {
          const templateIds = Array.isArray(this.rubricasSelecionadas) && this.rubricasSelecionadas.length > 0 ? this.rubricasSelecionadas.map(({ id }) => id) : [row.id];
          this.replicarModalData = { templateIds };
          this.replicarModalOpen = true;
        },
      }];
    },
    customResource: function () {
      const resource = this.apiResource(`v1/rh/clientes/${this.clientId}/template`);
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((response) => {
            if (Array.isArray(response)) {
              return response.map(({ catalogo, nome, ...rest }) => ({
                ...rest,
                catalogo,
                nome,
                descricaoTeros: nome?.nome,
                descricaoCliente: catalogo?.descricao || 'RUBRICA INVÁLIDA NA FOLHA DO CLIENTE',
              }));
            }

            return response;
          });
        }
      }
    },
    replicarFields: function () {
      return [
        {
          key: 'ano',
          name: 'Ano Base',
          type: this.$fieldTypes.SELECT,
          rel: { to: yearsOptions, key: "value", name: "text" },
          rules: [{ rule: 'required' }],
        },
      ];
    },
    title: function () {
      if (this.selectedClient) {
        return `Definições de Dispêndio: ${this.selectedClient.fantasia}`;
      }

      return `Definições de Dispêndio`;
    },
  },
  data: function () {
    return {
      replicarModalData: {},
      replicarModalOpen: false,
      rubricasSelecionadas: [],
      opts: {
        descricoesCliente: [],
        descricoesTeros: [],
        trabalho: [
          { id: BeneficiosEnum.LEI_DO_BEM, nome: BeneficiosLabels.LEI_DO_BEM },
          { id: BeneficiosEnum.LEI_DE_INFORMATICA, nome: BeneficiosLabels.LEI_DE_INFORMATICA },
          { id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA, nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA },
        ],
      },
    };
  },
  methods: {
    confirmDeleteMessage: function (row) {
      if (row) {
        const { descricaoCliente, descricaoTeros } = row;
        return `Deseja excluir o registro Cliente: ${descricaoCliente} | Teros: ${descricaoTeros}?`;
      }

      return 'Deseja excluir o registro?';
    },
    loadDescriptions: function () {
      this.apiResource(`v1/rh/clientes/${this.clientId}/templateCatalogo?status=Elegível`).get().then((response) => {
        this.opts.descricoesCliente = response
          .map(({ id, codigo, descricao, pareada }) => ({
            id,
            pareada,
            nome: `${codigo} — ${descricao}${ pareada !== 'Sim' ? ' [NÃO PAREADA]' : '' }`,
          }))
          .sort(({ nome: nomeA, pareada: pareadaA }, { nome: nomeB, pareada: pareadaB }) => pareadaA?.localeCompare(pareadaB || '') || nomeA?.localeCompare(nomeB || ''));
      });

      if (!this.opts.descricoesTeros.length) {
        this.apiResource(`v1/sistema/destaques`).get().then((response) => {
          this.opts.descricoesTeros = response.map(({ id, nome, trabalho }) => ({
            id,
            nome: `${id} — ${nome} (${BeneficiosLabels[trabalho]})`,
          }));
        });
      }
    },
    replicarRubricas: function () {
      const { ano, templateIds} = this.replicarModalData;

      if (!ano || !templateIds?.length) {
        return;
      }

      const resource = this.apiResource(`v1/rh/clientes/${this.clientId}/replicarTemplate`);
      return resource.save({ ano, templateIds });
    },
  },
  watch: {
    clientId: function () {
      this.opts.descricoesCliente = [];
      this.opts.descricoesTeros = [];
    }
  },
};
</script>

<style lang="scss" scoped>
.master-detail::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 52px - 275px) !important;
}
</style>
